<template>
  <div class="interface-menu-navbar-header">
    <div class="cadre-block-filter">
      <div class="right-block">
        <!-- DATE DEBUT -->
        <div
          class="date-input-vuetify mr-1"
          v-if="checkPermission('A&VTHFDDDF')"
        >
          <v-menu
            v-model="isDatePickerDebutVisible"
            :close-on-content-click="false"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date_debut"
                label="Date début"
                outlined
                readonly
                hide-details
                clearable
                v-on="on"
                v-bind="attrs"
                :persistent-placeholder="true"
                placeholder="Sélectionner un date"
                prepend-inner-icon="mdi-calendar"
                @click:clear="handleResetDate('date_debut')"
                color="#704ad1"
              ></v-text-field>
            </template>

            <v-date-picker
              v-model="date_debut"
              @input="handleDateTimePicker(date_debut, 'date_debut')"
              no-title
              locale="fr"
              :allowed-dates="disabledStartDate"
              color="#704ad1"
              item-color="#704ad1"
            >
            </v-date-picker>
          </v-menu>
        </div>
        <!-- DATE FIN -->
        <div
          class="date-input-vuetify mr-1"
          v-if="checkPermission('A&VTHFDDDF')"
        >
          <v-menu
            v-model="isDatePickerFinVisible"
            :close-on-content-click="false"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date_fin"
                label="Date fin"
                outlined
                readonly
                hide-details
                v-on="on"
                v-bind="attrs"
                clearable
                :persistent-placeholder="true"
                placeholder="Sélectionner un date"
                prepend-inner-icon="mdi-calendar"
                @click:clear="handleResetDate('date_fin')"
                color="#704ad1"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date_fin"
              @input="handleDateTimePicker(date_fin, 'date_fin')"
              no-title
              locale="fr"
              :allowed-dates="disabledEndDate"
              color="#704ad1"
              item-color="#704ad1"
            ></v-date-picker>
          </v-menu>
        </div>
        <!-- SELLECT GROUPE TABLE -->
        <div class="select-input-vuetify" v-if="checkPermission('A&VTHFGP')">
          <v-select
            v-model="grouped"
            :items="[
              { key: null, value: 'Rien' },
              ...getFilterRapportThGruopes
            ]"
            item-text="value"
            item-value="key"
            dense
            outlined
            hide-details
            class="ma-2"
            label="Groupe par"
            @change="handleFilter(false, true, true)"
            no-data-text="Aucun élément trouvé"
            :menu-props="{ bottom: true, offsetY: true }"
            :persistent-placeholder="true"
            color="#704ad1"
            item-color="#704ad1"
          ></v-select>
        </div>
        <!-- UPLOAD FILE -->
        <v-tooltip bottom color="#311B92">
          <template v-slot:activator="{ on }">
            <div v-on="on">
              <input-file-excel-num-dossier
                v-show="checkPermission('A&VTHFPFXLS')"
                hiddenContent="true"
                :value="numbers"
                @searchWithNumDossier="searchWithMultipleDossier"
              />
            </div>
          </template>
          <span>Choisir un fichier</span>
        </v-tooltip>
        <!-- COONTENT LOADING AND MESSAGE ERROR -->
        <!-- <div
          class="chargement chargement-loading-icon"
        >
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div> -->
        <v-progress-circular
          v-if="getLoaderRapportTh || loadingVue"
          indeterminate
          class="ml-2"
          color="#704ad1"
        ></v-progress-circular>
        <div class="notif-export-file-sci ml-2" v-if="getShowNotifExportFile">
          Préparation du fichier en cours ...
        </div>
        <div class="error-message ml-2 mr-2">
          <div v-if="getErrorRapportTh && !getLoaderRapportTh">
            <ul v-if="Array.isArray(getErrorRapportTh)" class="mb-0">
              <li v-for="(e, index) in getErrorRapportTh" :key="index">
                {{ e }}
              </li>
            </ul>
            <div v-else>{{ getErrorRapportTh }}</div>
          </div>
        </div>
      </div>
      <div class="left-block">
        <!-- SEARCH -->
        <div class="serach-input mr-2">
          <search
            v-if="checkPermission('A&VTHRE')"
            :value="search"
            @changeSearchValue="changeSearchValueGlobale"
            :dynamic="false"
            hiddenContent="true"
            placholderName="Rechercher"
          />
        </div>
        <!-- Verrouiller / Deverrouiller -->
        <Lock
          v-show="computedCheckAll && checkPermission('A&VTHVADD')"
          className="button-white-with-icon-color"
          :grouped="grouped"
        />
        <!-- MODIFIER STATUTS -->
        <select-statue
          v-show="computedCheckAll && checkPermission('A&VTHMQS')"
          @refreshData="handleFilter(true, true)"
          className="button-white-with-icon-color"
          :grouped="grouped"
        />
        <!-- EXPORT FILES -->
        <v-tooltip bottom color="#311B92">
          <template v-slot:activator="{ on, attrs }">
            <div
              size="sm"
              class="block-icon mr-2"
              v-on="on"
              v-bind="attrs"
              v-if="checkPermission('A&VTHTX')"
              @click.prevent.stop="exportFiles"
              :disabled="getShowNotifExportFile"
            >
              <font-awesome-icon
                icon="cloud-download-alt"
                :class="{ hidden: loadingExport }"
              />
              <v-progress-circular
                v-if="loadingExport"
                class="mr-2 mt-1"
                indeterminate
                width="2"
                size="10"
                color="#704ad1"
              ></v-progress-circular>
            </div>
          </template>
          <span>Exporter Fichier</span>
        </v-tooltip>
        <!-- VIDE ORDER -->
        <v-tooltip bottom color="#311B92">
          <template v-slot:activator="{ on, attrs }">
            <div
              size="sm"
              class="block-icon mr-2"
              v-on="on"
              v-bind="attrs"
              @click.prevent.stop="
                orderBy = null;
                order = null;
                handleFilter();
              "
              :disabled="getShowNotifExportFile"
            >
              <font-awesome-icon icon="retweet" />
            </div>
          </template>
          <span>Vide order</span>
        </v-tooltip>
        <!-- SELECT VUE  -->
        <div class="select-input-vuetify" v-if="checkPermission('A&VTHFV')">
          <v-select
            v-model="filterVueD"
            :items="getVueDynamiqueAV"
            @change="changeValue"
            item-text="name"
            item-value="id"
            :persistent-placeholder="true"
            dense
            outlined
            hide-details
            class="ma-2"
            label="Vue"
            no-data-text="Aucun élément trouvé"
            :menu-props="{ bottom: true, offsetY: true }"
            color="#704ad1"
            item-color="#704ad1"
          ></v-select>
        </div>
        <!-- FILTER -->
        <v-tooltip bottom color="#311B92">
          <template v-slot:activator="{ on, attrs }">
            <div
              size="sm"
              v-on="on"
              v-bind="attrs"
              @click="openPopover"
              class="block-icon mr-2 button-height-unset"
            >
              <IconFiltre></IconFiltre>
            </div>
          </template>
          <span>Choisir un Filtre</span>
        </v-tooltip>
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :nudge-width="350"
          origin="top right"
          content-class="popover-menu-analyse-vvalidation-facture"
        >
          <v-card>
            <v-divider class="line-hr-filter"></v-divider>
            <v-card-text class="body-card filter-content-popup">
              <v-form>
                <!-- Dépôts -->
                <v-autocomplete
                  :clearable="true"
                  v-if="checkPermission('A&VTHFD')"
                  v-model="depots"
                  :items="getFilterRapportThDepot"
                  return-object
                  dense
                  outlined
                  hide-details
                  class="mb-1"
                  label="Dépôts"
                  multiple
                  :loading="getLoaderFilterRapportTh"
                  :persistent-placeholder="true"
                  placeholder="Sélectionnez"
                  no-data-text="Aucun élément trouvé"
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                  <template v-slot:selection="{ item, index }">
                    <span
                      v-if="index < 0"
                      class="text-grey text-caption align-self-center"
                    >
                      (+{{ depots.length - 0 }} Dépôt(s))
                      <span hidden>{{ item.title }}</span>
                    </span>
                    <span
                      v-if="index === 0"
                      class="text-grey text-caption align-self-center"
                    >
                      (+{{ depots.length - 0 }} Dépôt(s))
                    </span>
                  </template>
                </v-autocomplete>
                <!-- Organismes -->
                <v-autocomplete
                  :clearable="true"
                  v-if="checkPermission('A&VTHFO')"
                  v-model="organismes"
                  :items="getFilterRapportThOrganisme"
                  return-object
                  dense
                  outlined
                  hide-details
                  class="mb-1"
                  label="Organismes"
                  multiple
                  :loading="getLoaderFilterRapportTh"
                  :persistent-placeholder="true"
                  placeholder="Sélectionnez"
                  no-data-text="Aucun élément trouvé"
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                  <template v-slot:selection="{ item, index }">
                    <span
                      v-if="index < 0"
                      class="text-grey text-caption align-self-center"
                    >
                      (+{{ organismes.length - 0 }} Organisme(s))
                      <span hidden>{{ item.title }}</span>
                    </span>
                    <span
                      v-if="index === 0"
                      class="text-grey text-caption align-self-center"
                    >
                      (+{{ organismes.length - 0 }} Organisme(s))
                    </span>
                  </template>
                </v-autocomplete>
                <!-- Statut -->
                <v-select
                  :clearable="true"
                  v-if="checkPermission('A&VTHFS')"
                  v-model="statut"
                  :items="[
                    { value: '', key: null },
                    ...getFilterRapportThStatut
                  ]"
                  item-text="value"
                  item-value="key"
                  return-object
                  dense
                  outlined
                  hide-details
                  class="mb-3"
                  label="Statut"
                  :persistent-placeholder="true"
                  placeholder="Sélectionnez"
                  no-data-text="Aucun élément trouvé"
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                </v-select>
                <!-- Verrouillé -->
                <v-select
                  :clearable="true"
                  v-if="checkPermission('A&VTHFVG')"
                  v-model="locked"
                  :items="[
                    { value: null, text: 'Tous' },
                    { value: 'non', text: 'Non' },
                    { value: 'oui', text: 'Oui' }
                  ]"
                  item-text="text"
                  item-value="value"
                  return-object
                  dense
                  outlined
                  hide-details
                  class="mb-4"
                  label="Verrouillé"
                  :persistent-placeholder="true"
                  placeholder="Sélectionnez"
                  no-data-text="Aucun élément trouvé"
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                </v-select>
              </v-form>
            </v-card-text>
            <v-card-actions class="popover-action-filter">
              <v-btn
                class="ma-2 capitalize-text"
                outlined
                color="#5E35B1"
                @click="validerPopover"
              >
                Appliquer
              </v-btn>
              <v-btn
                class="ma-2 ml-2 capitalize-text"
                text
                @click="closePopover"
              >
                Annuler
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-menu>
      </div>
    </div>
    <div class="content-cadre-table">
      <div class="body-box-rapport-analyse-validation">
        <div class="tabs-menu-style">
          <img
            src="@/assets/Icon ionic-md-arrow-dropright-circle.png"
            class="flesh-icon"
            :class="{ 'flesh-icon-rigth': showTabs == false }"
            @click="showTabs = !showTabs"
          />
          <div
            v-for="item in computedTabsList"
            :key="item.title"
            @click="handleChangeTabs(item)"
          >
            <div
              class="box-tabs"
              :class="{ 'Active-tabs-style': selectedTabs == item.key }"
            >
              <div class="icon">
                <img :src="item.img" />
              </div>
              <div class="title">{{ item.title }}</div>
            </div>
            <hr class="ligne" />
          </div>
        </div>

        <div
          class="tabs-body-style"
          :class="{
            'display-tabs': showTabs == false
          }"
        >
          <div class="tab-item-style" v-show="showTabs">
            <!-- <div class="title-tab-item">Statistique</div> -->
            <!-- <hr class="ligne" /> -->
            <div class="body-tab-item">
              <Charts
                :label="[
                  'Montant ADP',
                  'Montant facture à editer',
                  'Montant payé'
                ]"
                :data="computedGetDataStatisticChartRapportTh"
                :datasets="datasetsSoldeChart"
                :width="217"
                :height="170"
                :customLabelClass="true"
                class="display"
              />
              <template
                v-if="
                  computedGetDataStatisticChartRapportTh &&
                    computedGetDataStatisticChartRapportTh
                "
              >
                <div class="surface-box mt-1">
                  <div class="valueBoxStatue">
                    <span class="label-blue"> MONTANT FINAL :</span>
                    <span v-if="getDataStatisticRapportTh">
                      {{ getDataStatisticRapportTh.total_montant_final }}</span
                    >
                  </div>
                </div>
                <div class="surface-box mt-1">
                  <div class="valueBoxStatue">
                    <span class="label-green"> PAYÉ :</span>
                    <span v-if="getDataStatisticRapportTh">
                      {{ getDataStatisticRapportTh.paye }}</span
                    >
                  </div>
                </div>
                <div class="surface-box mt-1">
                  <div class="valueBoxStatue">
                    <span class="label-red"> ADP :</span>
                    <span v-if="getDataStatisticRapportTh">
                      {{ getDataStatisticRapportTh.ADP }}</span
                    >
                  </div>
                </div>
                <div class="surface-box mt-1">
                  <div class="valueBoxStatue">
                    <span class="label-blue"> FACTURE A EDITER :</span>
                    <span v-if="getDataStatisticRapportTh">
                      {{ getDataStatisticRapportTh.facture_edite }}</span
                    >
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>

        <div
          class="table-rapport-style-analyse-validation"
          :class="{
            'width-table-rapport': showTabs == false,
            'width-table-not-data': !computedRows || !computedRows.length
          }"
        >
          <v-data-table
            v-model="selectedTable"
            :headers="computedHeaderForVTable"
            :items="computedRows || []"
            class="table-facture"
            hide-default-footer
            hide-default-header
            :calculate-widths="false"
            :fixed-header="true"
            hide-action
            disable-sort
            disable-filtering
            :show-expand="grouped ? true : false"
            @item-expand="computedRows"
            :single-expand="singleExpand"
            :expanded.sync="expanded"
            :items-per-page="per_page"
            item-key="name"
            :no-data-text="
              getLoaderRapportTh
                ? 'Chargement... Veuillez patienter'
                : `Il n'y a aucune donnée à afficher dans ce tableau.`
            "
          >
            <!-- HEADERS TABLE -->
            <template v-slot:header="{ props }">
              <tr class="header-analyse-validation">
                <th
                  v-for="header in props.headers"
                  :key="header.key"
                  :style="{ width: header.width, minWidth: header.minWidth }"
                  :class="{
                    'sticky-header-1': header.key === 'check_all',
                    'sticky-header-2': header.key === 'data-table-expand',
                    'sticky-header-3':
                      header.key === 'numero_dossier' && grouped,
                    th_stiky_num_rapport_th_new:
                      header.thClass == 'th_stiky_num_rapport_th_new'
                  }"
                >
                  <span>
                    <v-checkbox
                      v-if="header.key == 'check_all'"
                      class="checked-item"
                      v-model="checkAll"
                      @change="checkAllFunction"
                      color="#704ad1"
                      :value="true"
                      :unchecked-value="false"
                      hide-details
                    ></v-checkbox>
                  </span>
                  <template v-if="header.key != 'data-table-expand'">
                    <span title-th> {{ header.label }}</span>
                    <span
                      class="d-inline-block ml-2"
                      v-if="header.key != 'check_all'"
                    >
                      <font-awesome-icon
                        @click.prevent.stop="
                          handleClickOrderData(header.key, 'ASC')
                        "
                        icon="arrow-up"
                        :class="
                          header.key === orderBy && order === 'ASC'
                            ? 'style-arrow-selected'
                            : 'style-arrow-not-selected'
                        "/>

                      <font-awesome-icon
                        @click.prevent.stop="
                          handleClickOrderData(header.key, 'DESC')
                        "
                        icon="arrow-down"
                        :class="
                          header.key === orderBy && order === 'DESC'
                            ? 'style-arrow-selected'
                            : 'style-arrow-not-selected'
                        "/></span
                  ></template>
                </th>
              </tr>
            </template>
            <!-- BODY TABLE -->
            <template
              v-for="data in ComputedgetAllHeader"
              v-slot:[`item.${data.key}`]="{ item }"
            >
              <td :key="data.key" class="td-expanded">
                <div v-if="grouped">-</div>
                <div v-else>
                  <span
                    v-if="
                      getFieldPropertyRapportTh(data.key) &&
                        getFieldPropertyRapportTh(data.key).editable
                    "
                  >
                    <EditableInput
                      :editable="
                        checkPermission('A&VTHMD') && !item.montant_min_lock
                      "
                      :champName="data.key"
                      :editableType="
                        getFieldPropertyRapportTh(data.key).editableType
                      "
                      :optionsSelect="
                        getFieldPropertyRapportTh(data.key).optionsSelect
                      "
                      :item="item"
                      :value="item[data.key]"
                      :type="getFieldPropertyRapportTh(data.key).type"
                      :updateFunction="updateDataInProjetTh"
                      :withFixe="true"
                      :class="{ 'commente-class': data.key == 'commentaire' }"
                      :title="item[data.key]"
                      label="full_name"
                    />
                  </span>
                  <div v-else>{{ item[data.key] }}</div>
                </div>
              </td>
            </template>
            <template v-slot:[`item.numero_dossier`]="{ item }">
              <td
                class="sticky-header-2"
                :class="{ 'sticky-header-3': grouped }"
              >
                <div v-if="grouped">{{ item.name }}</div>
                <div class="d-flex center" v-else>
                  <font-awesome-icon
                    icon="lock-open"
                    class="ml-1  mr-1 icon-color-cdg"
                    v-if="item.montant_min_lock == false"
                  />
                  <font-awesome-icon
                    icon="lock"
                    class="ml-1 mr-1 icon-color-cdg"
                    v-if="item.montant_min_lock == true"
                  />
                  <font-awesome-icon
                    :title="item.statue_paye_non_paye"
                    icon="circle"
                    :class="{
                      'red-color-icon': item.statue_paye_non_paye != 'PAYE',
                      'green-color-icon': item.statue_paye_non_paye == 'PAYE'
                    }"
                  />
                  <span> {{ item.numero_dossier }}</span>
                </div>
              </td>
            </template>
            <template v-slot:[`item.check_all`]="{ item }">
              <td class="sticky-header-1">
                <v-checkbox
                  class="checked-item"
                  v-model="item.check"
                  @change="checkAllVerif(item.check, item.index)"
                  color="#704ad1"
                  :value="true"
                  :unchecked-value="false"
                  hide-details
                ></v-checkbox>
              </td>
            </template>
            <template
              v-slot:[`item.data-table-expand`]="{ item }"
              v-if="grouped"
            >
              <td class="sticky-header-2">
                <button
                  class="btn btn-anomalie"
                  v-if="item && item.projects && item.projects.length > 0"
                  @click="toggleDetails(item)"
                >
                  <font-awesome-icon
                    class="icon-plus color-cdg"
                    :icon="['fas', 'square-plus']"
                    v-if="item.showDetails == false"
                    title="Afficher les dossiers"
                  />
                  <font-awesome-icon
                    class="icon-plus color-cdg"
                    :icon="['fas', 'square-minus']"
                    v-if="item.showDetails == true"
                    title="Masquer les dossiers"
                  />
                </button>
              </td>
            </template>
            <!-- TABLE EXPANDED -->
            <template #expanded-item="{ headers,item }">
              <td
                class="td-expanded"
                :colspan="headers.length"
                v-if="
                  item.projects &&
                    item.projects.length > 0 &&
                    item.showDetails == true
                "
              >
                <table :items="item.projects" class="table-expanded">
                  <tbody>
                    <tr
                      v-for="(expandItem, index) in item.projects"
                      :key="item.name + index"
                    >
                      <!-- {{headers}} -->
                      <td
                        class="td-expanded"
                        v-for="(col, i) in ComputedgetAllHeader"
                        :key="'column' + i + 'p' + index"
                        :class="{
                          'col-check-obligee': col.key == 'check_all',
                          'col-plus-obligee': col.key == 'data-table-expand',
                          'num-lot-td': col.key == 'numero_dossier' && grouped,
                          col_stiky_num_rapport_th_new:
                            col.key == 'numero_dossier' && !grouped
                        }"
                      >
                        <div v-if="col.key == 'check_all'">
                          <v-checkbox
                            class="checked-item"
                            v-model="expandItem.check"
                            @change="checkAllVerifDetails(item, expandItem)"
                            color="#704ad1"
                            :value="true"
                            :unchecked-value="false"
                            hide-details
                          ></v-checkbox>
                        </div>
                        <div
                          v-else-if="col.key == 'data-table-expand'"
                          class="cp-show"
                        ></div>
                        <div v-else-if="col.key == 'numero_dossier'">
                          <div class="d-flex center">
                            <font-awesome-icon
                              icon="lock-open"
                              class="ml-1 color-cdg"
                              v-if="expandItem.montant_min_lock == false"
                            />
                            <font-awesome-icon
                              icon="lock"
                              class="ml-1 color-cdg"
                              v-if="expandItem.montant_min_lock == true"
                            />
                            <font-awesome-icon
                              :title="expandItem.statue_paye_non_paye"
                              icon="circle"
                              :class="{
                                'red-color-icon':
                                  expandItem.statue_paye_non_paye != 'PAYE',
                                'green-color-icon':
                                  expandItem.statue_paye_non_paye == 'PAYE'
                              }"
                            />
                            <span> {{ expandItem.numero_dossier }}</span>
                          </div>
                        </div>
                        <div
                          class="vertical-align"
                          v-else-if="
                            getFieldPropertyRapportTh(col.key) &&
                              getFieldPropertyRapportTh(col.key).editable
                          "
                        >
                          <EditableInput
                            :editable="
                              checkPermission('A&VTHMD') &&
                                !expandItem.montant_min_lock
                            "
                            :champName="col.key"
                            :editableType="
                              getFieldPropertyRapportTh(col.key).editableType
                            "
                            :optionsSelect="
                              getFieldPropertyRapportTh(col.key).optionsSelect
                            "
                            :item="expandItem"
                            :value="expandItem[col.key]"
                            :type="getFieldPropertyRapportTh(col.key).type"
                            :updateFunction="updateDataInProjetTh"
                            :withFixe="true"
                            label="full_name"
                          />
                        </div>
                        <div v-else>{{ expandItem[col.key] }}</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </template>
          </v-data-table>
        </div>
      </div>
    </div>
    <!-- FOOTER PAGINATION PERPAGE -->
    <div
      class="footer-style-table-analyse-validation"
      :class="{ 'right-position': showTabs == false }"
    >
      <div
        class="text-center pagination-ta
      ble"
      >
        <v-pagination
          v-model="page"
          :length="totalPages"
          :total-visible="7"
          @input="pagination"
          color="#704ad1"
        ></v-pagination>
      </div>
      <div class="select-input-vuetify">
        <v-select
          v-model="per_page"
          :items="computedperPageList"
          @change="changePerPage"
          label="Eléments par page"
          outlined
          dense
          hide-details
          no-data-text="Aucun élément trouvé"
          :menu-props="{
            left: true,
            offsetX: true
          }"
          color="#704ad1"
          item-color="#704ad1"
        ></v-select>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
export default {
  data() {
    return {
      orderBy: null,
      order: null,
      checkAll: false,
      grouped: null,
      date_fin: null,
      date_debut: null,
      statut: null,
      numbers: null,
      search: null,
      filterVueD: null,
      locked: null,
      showTabs: true,
      selectedTabs: 0,
      zoom: false,
      depots: [],
      organismes: [],
      page: 1,
      per_page: 100,
      TabsList: [
        {
          title: 'Statistique',
          img: 'image/Icon-ionic-ios-pie.png',
          key: 0
        }
      ],
      perPageList: [
        { value: 100, text: 100 },
        { value: 200, text: 200 },
        { value: 300, text: 300 }
      ],
      perPageListGrouped: [
        { value: 10, text: 10 },
        { value: 50, text: 50 },
        { value: 100, text: 100 }
      ],
      fields: [
        {
          key: 'check_all',
          label: '',
          width: '40px !important',
          minWidth: '40px !important',
          class: 'sticky-header-1'
        },
        {
          key: 'data-table-expand',
          label: '',
          width: '40px !important',
          minWidth: '40px !important',
          class: 'sticky-header-2'
        }
      ],
      datasetsSoldeChart: {
        position: 'bottom',
        title: '',
        backgroundColor: ['#ff0000ba', '#0d09b08a', '#0baa0b7d']
      },
      isDatePickerDebutVisible: false,
      isDatePickerFinVisible: false,
      menu: false,
      expanded: [],
      singleExpand: false,
      selectedTable: [],
      loadingExport: false,
      loadingVue: false
    };
  },
  computed: {
    ...mapGetters([
      'getShowNotifExportFile',
      'checkPermission',
      'getDataRapportTh',
      'getVueDynamiqueAV',
      'getFilterRapportThGruopes',
      'getFilterRapportThDepot',
      'getFilterRapportThOrganisme',
      'getFilterRapportThStatut',
      'getDataStatisticRapportTh',
      'getDataStatisticChartRapportTh',
      'getTotalRapportTh',
      'getErrorRapportTh',
      'getFieldPropertyRapportTh',
      'getLoaderFilterRapportTh',
      'getLoaderRapportTh',
      'getLoaderExportDataRapportTh'
    ]),
    dynamicColumnName() {
      return this.ComputedgetAllHeader.key;
    },
    totalPages() {
      if (this.getTotalRapportTh) {
        return Math.ceil(this.getTotalRapportTh / this.per_page);
      }
      return this.getTotalRapportTh;
    },
    computedperPageList() {
      return this.grouped ? this.perPageListGrouped : this.perPageList;
    },
    computedGetDataStatisticChartRapportTh() {
      return Object.values(this.getDataStatisticChartRapportTh);
    },
    computedRows() {
      if (!this.getDataRapportTh) {
        return [];
      }
      if (this.type === 'valides') {
        return this.getDataRapportTh.filter(
          row => !row.anomalies || row.anomalies.length == 0
        );
      } else if (this.type === 'invalides') {
        return this.getDataRapportTh.filter(
          row => row.anomalies && row.anomalies.length > 0
        );
      }

      return this.getDataRapportTh;
    },
    computedCheckAll() {
      var table = [];
      if (!this.getLoaderRapportTh) {
        table =
          !this.grouped &&
          this.computedRows.length &&
          !this.computedRows[0].projects
            ? this.computedRows.filter(item => item.check == true)
            : this.computedRows.length && this.computedRows[0].projects
            ? this.computedRows
                .map(item => {
                  return item.projects.filter(i => i.check == true);
                })
                .flat()
            : [];
      }

      return table.length ? true : false;
    },
    ComputedgetAllHeader() {
      let field = this.grouped
        ? this.fields
        : this.fields.filter(item => item.key != 'data-table-expand');
      if (this.filterVueD != null) {
        let coloms = [];
        coloms = this.getVueDynamiqueAV.find(item => item.id == this.filterVueD)
          ?.columns;
        field = [...field, ...coloms];
      }
      let indexOfNum = field.findIndex(i => i.key == 'numero_dossier');
      if (indexOfNum >= 0) {
        if (this.grouped) {
          field[indexOfNum].thClass = 'num-lot-th';
          field[indexOfNum].tdClass = 'num-lot-td';
        } else {
          field[indexOfNum].thClass = 'th_stiky_num_rapport_th_new';
          field[indexOfNum].tdClass = 'col_stiky_num_rapport_th_new';
        }
      }
      return field;
    },
    computedHeaderForVTable() {
      return this.ComputedgetAllHeader.map(i => {
        return { ...i, text: i.label, value: i.key };
      });
    },
    computedTabsList() {
      let list = this.TabsList;
      return list;
    }
  },
  methods: {
    ...mapActions([
      'exportFileNewRapportTh',
      'fetchDataForFilterRapportTh',
      'fetchDataForRapportTh',
      'getAllVueDynamiqueAV',
      'fetchStatisticDataForRapportTh',
      'updateDataInProjetTh',
      'getSettingOrgenismeTh',
      'getSettingFilialeTh',
      'fetchAllRegieForAdmin',
      'fetchEmployeParTypeTh',
      'getAllProFilial'
    ]),
    changeValue() {
      this.loadingVue = true;
      setTimeout(() => {
        this.loadingVue = false;
      }, 1000);
    },
    toggleDetails(item) {
      item.showDetails = !item.showDetails;
      if (item.showDetails == true) {
        if (!this.expanded.includes(item)) {
          this.expanded.push(item);
        }
      } else {
        const index = this.expanded.findIndex(
          expandedItem => expandedItem === item
        );
        if (index !== -1) {
          this.expanded.splice(index, 1);
        }
      }
    },
    handleClickOrderData(orderBy, order) {
      this.orderBy = orderBy;
      this.order = order;
      this.handleFilter();
    },
    checkAllVerifDetails(data, item) {
      if (item.check == false && data.check == true) {
        data.check = false;
      }
      if (data.check == true && this.checkAll == false) {
        this.checkAll = true;
      }
    },
    checkAllVerif(item, i) {
      if (item == false && this.checkAll == true) {
        this.checkAll = false;
      }
      if (this.grouped) {
        item.projects.map(i => (i.check = item.check));
      }
    },
    checkAllFunction() {
      if (this.grouped) {
        this.computedRows.map(item => {
          item.check = this.checkAll;
          item.projects.map(project => {
            project.check = this.checkAll;
          });
        });
      } else {
        this.computedRows.map(item => {
          item.check = this.checkAll;
        });
      }
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return;
      if (item.anomalies) return 'ligneAnomalie';
      else return 'ligneAlert';
    },

    handleFilter(refreshStatistic, checkReset, resetPerPage, refreshPaginate) {
      if (resetPerPage) {
        this.per_page = this.grouped ? 10 : 100;
      }
      if (!refreshPaginate) {
        this.page = 1;
      }
      this.fetchDataForRapportTh({
        grouped: this.grouped,
        date_fin: this.date_fin,
        date_debut: this.date_debut,
        orderBy: this.orderBy,
        order: this.order,
        statut: this.statut,
        numbers: this.numbers,
        search: this.search,
        locked: this.locked,
        depots: this.depots,
        organismes: this.organismes,
        page: this.page,
        per_page: this.per_page
      });
      if (refreshStatistic) {
        this.fetchStatisticDataForRapportTh({
          grouped: this.grouped,
          date_fin: this.date_fin,
          date_debut: this.date_debut,
          statut: this.statut,
          numbers: this.numbers,
          search: this.search,
          locked: this.locked,
          depots: this.depots,
          organismes: this.organismes
        });
      }
      if (checkReset) {
        this.checkAll = false;
      }
    },
    getAllDataForFilterDynamicAction() {
      this.fetchDataForFilterRapportTh({
        date_debut: this.date_debut,
        date_fin: this.date_fin
      });
    },
    openPopover() {
      this.menu = true;
    },
    closePopover() {
      this.menu = false;
      this.depots = [];
      this.organismes = [];
      this.statut = null;
      this.locked = null;
      this.handleFilter();
    },
    validerPopover() {
      this.handleFilter();
      this.menu = false;
    },
    disabledStartDate(date) {
      if (this.date_fin) {
        return (
          this.date_fin && moment(date, 'YYYY-MM-DD') <= new Date(this.date_fin)
        );
      }
      return true;
    },
    disabledEndDate(date) {
      if (this.date_debut) {
        return (
          this.date_debut &&
          moment(date, 'YYYY-MM-DD').add(1, 'days') > new Date(this.date_debut)
        );
      }
      return true;
    },
    handleResetDate(name) {
      this[name] = null;
      this.depots = [];
      this.organismes = [];
      this.statut = null;
      this.locked = null;
      this.isDatePickerDebutVisible = false;
      this.isDatePickerFinVisible = false;
      this.getAllDataForFilterDynamicAction();
      this.handleFilter(true);
    },
    handleDateTimePicker(value, name) {
      this[name] = value;
      this.depots = [];
      this.organismes = [];
      this.statut = null;
      this.locked = null;
      this.isDatePickerDebutVisible = false;
      this.isDatePickerFinVisible = false;
      this.getAllDataForFilterDynamicAction();
      this.handleFilter(true);
    },
    searchWithMultipleDossier(value) {
      if (this.numbers != value) {
        this.numbers = value;
        this.handleFilter();
      }
    },
    async changeSearchValueGlobale(e) {
      this.search = e;
      this.handleFilter();
    },
    pagination(paginate) {
      this.page = paginate;
      this.handleFilter(false, false, false, true);
    },
    changePerPage() {
      this.page = 1;
      this.handleFilter();
    },
    async exportFiles() {
      this.loadingExport = true;
      const response = await this.exportFileNewRapportTh({
        date_fin: this.date_fin,
        date_debut: this.date_debut,
        statut: this.statut,
        numbers: this.numbers,
        search: this.search,
        locked: this.locked,
        depots: this.depots,
        organismes: this.organismes,
        view: this.filterVueD,
        ids: this.computedRows
          .filter(item => item.check == true)
          .map(item => item.id)
      });
      if (response.success) {
        this.$alert('', response.msg, 'success');
      } else {
        this.$alert('', 'Une erreur est survenue', 'danger');
      }
      this.loadingExport = false;
    },
    handleChangeTabs(tab) {
      this.selectedTabs = tab.key;
    }
  },
  async mounted() {
    this.getAllProFilial();
    this.getAllDataForFilterDynamicAction();
    this.handleFilter(true);
    const responseFields = await this.getAllVueDynamiqueAV({ blocked: false });
    if (responseFields && this.getVueDynamiqueAV.length) {
      this.filterVueD = this.getVueDynamiqueAV[0].id;
    }
    this.getSettingOrgenismeTh();
    this.getSettingFilialeTh({
      type: null,
      type_societe: null,
      search: null
    });
    this.fetchAllRegieForAdmin();
    this.fetchEmployeParTypeTh();
  },
  components: {
    IconFiltre: () => import('@/assets/img/IconFiltre.vue'),
    Lock: () => import('../componentRapportTh/Lock.vue'),
    inputFileExcelNumDossier: () =>
      import('../componentRapportTh/inputFileExcelNumDossier.vue'),
    selectStatue: () => import('../componentRapportTh/selectStatue.vue'),
    search: () => import('@/views/component/SearchInputComponent.vue'),
    Charts: () => import('@/views/component/customChart.vue'),
    EditableInput: () => import('@/views/component/EditableInput.vue')
  }
};
</script>
<style scoped lang="scss">
.popover-menu-analyse-vvalidation-facture {
  background-color: #704ad1;
  right: 7px;
  left: unset !important;
  top: 97px !important;
}
.content-cadre-table {
  // height: calc(100vh - 223px) !important;
  .body-box-rapport-analyse-validation {
    display: flex;
    .tabs-menu-style {
      background-color: transparent;
      border-radius: 41px;
      width: 74px;
      position: relative;
      margin-right: 14px;
      display: inline;
      text-align: center;
      padding: 5px 1px;
      .flesh-icon {
        width: 23px;
        margin-bottom: 5px;
      }
      .flesh-icon-rigth {
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
      }

      .box-tabs {
        background-color: #fff;
        border-radius: 10px;
        text-align: center;
        height: 62px;
        margin: 0px 4px 0px 4px;
        padding: 4px;

        .icon {
          color: #5d5d5d;
          margin-bottom: 5px;
          img {
            width: 22px;
          }
        }
        .title {
          font-family: 'Montserrat', sans-serif;
          font-size: 9px !important;
          font-weight: 600;
          color: #5d5d5d;
          line-height: 1.5;
        }
        &:focus,
        &:hover {
          background-color: #8d8cb7;
          .title,
          .icon {
            color: #fff;
            img {
              filter: brightness(0) invert(1);
            }
          }
        }
      }
      .Active-tabs-style {
        background-color: #704ad1;
        .title,
        .icon {
          color: #fff;
          img {
            filter: brightness(0) invert(1);
          }
        }
      }
      .ligne {
        width: 70%;
        margin: auto;
        margin-top: 0px;
        margin-bottom: 5px;
      }
    }
    .tabs-body-style {
      background-color: transparent;
      padding: 0px;
    }
  }
  .display-tabs {
    display: none;
  }
  .custom-chart-component {
    width: 100%;
  }
}
</style>
<style lang="scss" scoped>
.body-box-rapport-analyse-validation {
  .tabs-body-style {
    margin-right: -41px;
    .tab-item-style {
      display: inline !important;
      .title-tab-item {
        font-family: 'Montserrat', sans-serif;
        font-size: 15px;
        font-weight: 500;
        color: #5d5d5d;
      }
      .ligne {
        width: 100%;
        margin: auto;
        margin-top: 7px;
        margin-bottom: 7px;
      }
      .body-tab-item {
        .badge-style {
          min-width: 51px;
          padding-top: 5px !important;
          padding-bottom: 5px !important;
          background-color: #6866b7 !important;
          color: #fff !important;
          border: 1px solid #6866b7;
          border-radius: 19px;
        }
      }
    }
  }
  .surface-box {
    .title {
      color: #5d5d5d;
      font-family: 'Montserrat', sans-serif;
      font-size: 13px;
      font-weight: 500;
      text-align: left;
      margin-left: 19%;
      margin-bottom: 2px;
    }
    .valueBox {
      text-align: center;
      margin: auto;
      border-radius: 10px;
      background-color: #f5f5ff;
      width: 200px;
      padding: 10px;
      font-size: 17px;
      font-weight: 500;
      font-family: 'Montserrat', sans-serif;
      color: #ff7f8b;
    }
    .valueBoxStatue {
      // text-align: center;
      // margin: auto;
      border-radius: 10px;
      background-color: #f6f5fb;
      width: 100%;
      padding: 5px 10px;
      font-size: 11px;
      font-weight: 500;
      font-family: 'Montserrat', sans-serif;
      color: #5d5d5d;
      display: flex;
      justify-content: space-between;
      // div {
      //   width: 50%;
      .label-green {
        color: #13d613 !important;
      }
      .label-red {
        color: red !important;
      }
      .label-blue {
        color: #3f47aa !important;
      }
      // }
    }
  }

  .table-rapport-style-analyse-validation {
    width: 100%;
    padding: 0px 245px 0px 50px;
    z-index: 1;
    .num-lot-td {
      left: 77px !important;
      position: sticky;
      padding: 0px;
      background-color: #f6f5fb;
    }
    .col-check-obligee {
      left: 0px !important;
      position: sticky;
      background-color: #f6f5fb;
      padding: 0px;
    }
    .col-plus-obligee {
      left: 39px !important;
      position: sticky;
      padding: 0px;
      background-color: #f6f5fb;
    }
    .checked-item {
      margin-left: 7px;
      // width: 11px;
      margin-top: -3px;
    }
    .col-plus-obligee {
      min-width: 40px !important;
      width: 40px !important;
    }
    .num-lot-td {
      min-width: 150px !important;
      width: 150px !important;
    }
    .col-check-obligee {
      min-width: 40px !important;
      width: 40px !important;
    }
    .col_stiky_num_rapport_th_new {
      position: sticky;
      left: 30px;
      // width: 150px !important;
      // min-width: 150px !important;
      text-align: start;
    }
    .th_stiky_num_rapport_th_new {
      position: sticky;
      left: 39px;
      background-color: #fff;
      z-index: 20 !important;
      width: 150px !important;
      min-width: 150px !important;
    }
    .table {
      height: calc(100% - 50px);
    }
    .b-table-sticky-header {
      max-height: calc(100vh - 175px) !important;
      height: calc(100vh - 175px);
      padding-bottom: 0px;
      margin-bottom: 0px;
    }
  }
  .td-expanded {
    z-index: 1 !important;
    table {
      width: 100%;
      display: table-row;
      margin: 0px;
      border-collapse: collapse;
    }
  }
  .width-table-rapport {
    width: 100%;
    padding: 0px 79px 0px 0px;
    z-index: 1;
  }
  .width-table-not-data {
    width: 100%;
    padding: 0px 2px 0px 50px;
    z-index: 1;
  }
}
.footer-style-table-analyse-validation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 18px 0px 319px;
  &.right-position {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px 18px 0px 85px;
  }
}
.custom-spinner-export {
  height: 15px;
  width: 15px;
  position: absolute;
  background: #ffffffa1;
}
.block-icon {
  .spinner-border {
    height: 15px;
    width: 15px;
  }
}
</style>
<style lang="scss">
.commente-class {
  .show-value {
    span {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}
.table-rapport-style-analyse-validation {
  .table-facture .v-data-table__wrapper {
    height: calc(100vh - 196px) !important;
  }
  .table-facture tbody .sticky-header-1 {
    background-color: #f6f5fb;
    position: sticky;
    left: 0px;
    z-index: 4;
  }
  .table-facture tbody .sticky-header-2 {
    background-color: #f6f5fb;
    position: sticky;
    left: 39px;
    z-index: 4;
  }
  .table-facture tbody .sticky-header-3 {
    background-color: #f6f5fb;
    position: sticky;
    left: 77px;
    z-index: 4;
  }
}
.table-rapport-style-analyse-validation {
  .th-check-obligee {
    z-index: 10 !important;
  }
  .th-plus-obligee {
    z-index: 11 !important;
    width: 43px !important;
    min-width: 43px !important;
  }
  .num-lot-th {
    z-index: 7 !important;
  }
  .align-td-item {
    padding: 0px !important;
    .td-expanded {
      justify-content: center;
    }
  }
  .center-th-montant {
    display: flex !important;
    position: relative;
  }
}

.cpl-7 {
  padding-left: 7px;
}
.cp-show {
  // padding: 0px 21px;
}
.style-arrow-not-selected {
  color: #aaacb0;
}
.style-arrow-selected {
  color: #4d4bac;
}
.h-31-px {
  height: 31px;
}
.header-analyse-validation {
  border: 0px;
  position: sticky;
  top: -1px;
  background-color: #fff;
  box-shadow: none;
  z-index: 7;
  text-align: center;
  .title-th {
    text-transform: lowercase;
  }
  .sticky-header-1 {
    background-color: #fff;
    position: sticky;
    left: 0px;
    z-index: 10;
    top: 0px;
  }
  .sticky-header-2 {
    background-color: #fff;
    position: sticky;
    left: 39px;
    z-index: 10;
    top: 0px;
  }
  .sticky-header-3 {
    background-color: #fff;
    position: sticky;
    left: 77px;
    z-index: 10;
    top: 0px;
  }
  th {
    text-align: center;
    min-width: 150px !important;
    max-width: 150px !important;
    width: 150px !important;
    border: 1px solid #e5e5e5 !important;
    font-size: 11px;
    font-weight: 600;
    color: black;
    font-family: 'Montserrat', sans-serif;
    text-transform: lowercase;
    // white-space: nowrap;
    height: 50px !important;
    padding: 0px !important;
  }
  .v-input--selection-controls {
    margin-top: 0px !important;
    padding-top: 0px !important;
    margin-left: 7px !important;
  }
}
</style>
